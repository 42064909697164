import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import "../../Styles/CreatePackage.css";
import AdminComponent from "../../Componet/Layout/AdminComponent";

const LongAds = () => {
  const navigate = useNavigate();
  const [auth] = useAuth(); // Ensure 'useAuth' is wrapped in a provider
  const [videotitle, setVideotitle] = useState("");
  const [description, setDescription] = useState("");
  const [Link, setLink] = useState("");
  const [code, setCode] = useState("");
  const [ads, setAds] = useState([]);
  const [editingAdId, setEditingAdId] = useState(null);

  // Fetch all ads
  const getAllAds = async () => {
    try {
      const { data } = await axios.get(
        "https://backend-code-9qn4.onrender.com/api/v1/longearn/get-ads"
      );
      setAds(data?.ads || []);
    } catch (error) {
      console.error(error);
      toast.error("Error fetching ads");
    }
  };

  // Handle ad creation or update
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!videotitle || !Link || !code) {
      toast.error("Please fill all the fields");
      return;
    }

    try {
      const adData = { videotitle, Link, code };
      if (editingAdId) {
        // Update existing ad
        const { data } = await axios.put(
          `https://backend-code-9qn4.onrender.com/api/v1/longearn/update/${editingAdId}`,
          adData
        );
        if (data.success) {
          toast.success(data.message);
        }
      } else {
        // Create new ad
        const { data } = await axios.post(
          "https://backend-code-9qn4.onrender.com/api/v1/longearn/create-longads",
          adData
        );
        if (data.success) {
          toast.success(data.message);
        }
      }

      getAllAds(); // Refresh ads
      resetForm();
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong!");
    }
  };

  // Delete ad
  const handleDelete = async (adId) => {
    try {
      const { data } = await axios.delete(
        `https://backend-code-9qn4.onrender.com/api/v1/longearn/delete/${adId}`
      );
      if (data.success) {
        toast.success(data.message);
        getAllAds();
      } else toast.error(data.message);
    } catch (error) {
      console.error(error);
      toast.error("Error deleting ad");
    }
  };

  // Reset form
  const resetForm = () => {
    setVideotitle("");
    setLink("");
    setCode("");
    setEditingAdId(null);
  };

  // Edit ad
  const handleEdit = (ad) => {
    setVideotitle(ad.videotitle);
    setLink(ad.Link);
    setCode(ad.code);
    setEditingAdId(ad._id);
  };

  useEffect(() => {
    getAllAds();
  }, []);

  return (
    <div className="dashboard-container bg-light">
      <div className="row">
        <div className="col-md-3">
          <AdminComponent />
        </div>
        <div className="col-md-8">
          <h1 className="text-center p-4 mb-4">
            {editingAdId ? "Edit Ad" : "Create Ads"}
          </h1>

          <div className="card w-75 p-3 bg-dark text-white mb-3 p-5">
            <input
              type="text"
              value={videotitle}
              placeholder="Ad Title"
              className="form-control mb-3"
              onChange={(e) => setVideotitle(e.target.value)}
            />
            <textarea
              value={Link}
              placeholder="Ad Link"
              className="form-control mb-3"
              onChange={(e) => setLink(e.target.value)}
            />
            <input
              type="text"
              value={code}
              placeholder="Video Code"
              className="form-control mb-3"
              onChange={(e) => setCode(e.target.value)}
            />
            <button className="btn btn-primary w-100" onClick={handleSubmit}>
              {editingAdId ? "Update Ad" : "Create Ad"}
            </button>
          </div>

          <h1 className="text-center mt-5">Ads List</h1>
          {ads.length === 0 ? (
            <p className="text-center">No ads found</p>
          ) : (
            ads.map((ad) => (
              <div key={ad._id} className="card m-2 package-card">
                <div className="card-body">
                  <h5>Title: {ad.videotitle}</h5>
                  <p>Link: {ad.Link}</p>
                  <p>Code: {ad.code}</p>
                  <button
                    className="btn btn-warning me-2"
                    onClick={() => handleEdit(ad)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(ad._id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default LongAds;
