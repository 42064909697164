import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../Context/auth";
import Layout from "../../Componet/Layout/Layout";
import logo from "../../Assets/sitelogo.png";
import backgroundImage from "../../Assets/20255.jpg";
const TotalTeam = () => {
  const [auth] = useAuth();
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [earnings, setEarnings] = useState(null);
  const [activeUsers, setActiveUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  const [referralCode, setReferralCode] = useState("");
  const [copyTooltipText, setCopyTooltipText] = useState("Copy");
  const [referralLink, setReferralLink] = useState("");

  const currencySymbol = () => {
    if (!earnings?.currency || earnings.currency === "") {
      return "Rs"; // Default to Rs if currency is null, undefined, or empty
    } else if (earnings.currency === "PKR") {
      return "Rs";
    } else if (earnings.currency === "USD") {
      return "$";
    } else {
      return ""; // Default case for unsupported currencies
    }
  };

  const displayCurrency = currencySymbol();
  useEffect(() => {
    const fetchUserReferrals = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/users/total-referrals"
        );
        setTotalReferrals(response.data.totalReferrals);
        setReferrals(response.data.referralDetails);

        // Calculate active and inactive users
        const activeCount = response.data.referralDetails.filter(
          (referral) => referral.packageStatus === "Active"
        ).length;
        const inactiveCount =
          response.data.referralDetails.length - activeCount;

        setActiveUsers(activeCount);
        setInactiveUsers(inactiveCount);
      } catch (err) {
        setError("Failed to fetch referrals.");
      }
    };
    fetchUserReferrals();
  }, []);

  useEffect(() => {
    const fetchUserEarnings = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/users/earnings"
        );
        setEarnings(response.data);
      } catch (err) {
        setError("Failed to fetch earnings.");
      } finally {
        setLoading(false);
      }
    };
    fetchUserEarnings();
  }, []);

  useEffect(() => {
    const fetchReferralCode = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/users/get-refferalcode"
        );
        setReferralCode(response.data.referralCode);
      } catch (err) {
        setError("Failed to fetch referral code.");
      }
    };
    fetchReferralCode();
  }, []);
  useEffect(() => {
    const fetchReferralLink = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/users/get-refferallink"
        );
        setReferralLink(response.data.referralLink);
      } catch (err) {
        setError("Failed to fetch referral code.");
      }
    };
    fetchReferralLink();
  }, []);

  const handleCopyReferralCode = () => {
    navigator.clipboard.writeText(referralCode).then(() => {
      setCopyTooltipText("Copied!");
      setTimeout(() => {
        setCopyTooltipText("Copy");
      }, 2000);
    });
  };
  const handleCopyreferralLink = () => {
    // Create a temporary input element to hold the full text to copy
    const fullText = `${referralLink} 
  `;
    const tempInput = document.createElement("input");
    tempInput.value = fullText;

    // Append the temporary input to the body
    document.body.appendChild(tempInput);

    // Select the content of the temporary input
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected content
    document.execCommand("copy");

    // Remove the temporary input from the document
    document.body.removeChild(tempInput);

    // Update the tooltip text to indicate that the content was copied
    setCopyTooltipText("Copied!");

    // Optionally, you can reset the tooltip text after a few seconds
    setTimeout(() => setCopyTooltipText("Copy"), 2000);
  };
  const formatAmount = (amount) => {
    return parseFloat(amount)
      .toFixed(4)
      .replace(/(\.0+|(?<=\.\d)0+)$/, "");
  };
  const [currentPage, setCurrentPage] = useState(1);
  const referralsPerPage = 10;

  const totalPages = Math.ceil(referrals.length / referralsPerPage);
  const startIndex = (currentPage - 1) * referralsPerPage;
  const currentReferrals = referrals.slice(
    startIndex,
    startIndex + referralsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Layout>
      <div
        className="dashboard-container"
        style={{
          padding: "40px",
          borderRadius: "20px",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)",
          textAlign: "center",
          maxWidth: "750px",
          margin: "100px auto",
          position: "relative",
          overflow: "hidden",
          color: "white",
          backgroundImage: `url(${backgroundImage})`, // Correctly imported image
          backgroundSize: "cover", // Adjusted to fit the card properly
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Main Content */}
        <div style={{ position: "relative", zIndex: 2 }}>
          <h1
            style={{
              fontSize: "42px",
              fontWeight: "700",
              textTransform: "uppercase",
              color: "black",
              marginBottom: "20px",
            }}
          >
            Teams
          </h1>

          <div className="user-info" style={{ marginTop: "30px" }}>
            <img
              src={logo}
              alt="Dashboard Logo"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                border: "4px solid #00ffff",
                boxShadow: "0px 5px 20px rgba(0, 255, 255, 0.5)",
              }}
            />

            <div
              className="info-box"
              style={{
                marginTop: "20px",
                padding: "20px",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "15px",
                boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  color: "black",
                }}
              >
                👨 {auth?.user?.username}
              </p>
              <p style={{ fontSize: "18px", marginBottom: "5px" }}>
                <strong
                  style={{
                    color: "#000",

                    padding: "10px",
                  }}
                >
                  Earnings:
                </strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span
                    style={{
                      color: "#000",

                      padding: "10px",
                    }}
                  >
                    {formatAmount(earnings ? earnings.earnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong
                  style={{
                    color: "#000",

                    padding: "10px",
                  }}
                >
                  Total Earnings:
                </strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span
                    style={{
                      color: "#000",

                      padding: "10px",
                    }}
                  >
                    {formatAmount(earnings ? earnings.totalEarnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="card p-4">
              <div className="bg-white p-3 rounded shadow-sm">
                <label
                  className="d-block text-center mb-2"
                  style={{ fontSize: "20px" }}
                >
                  <strong>Total Team</strong>
                </label>
                <p
                  className="text-center mb-0"
                  style={{ fontSize: "30px", color: "black" }}
                >
                  <strong>{totalReferrals}</strong>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3">
            <div className="card p-4">
              <div className="bg-white p-3 rounded shadow-sm">
                <label
                  className="d-block text-center mb-2"
                  style={{ fontSize: "20px" }}
                >
                  <strong>Active Members</strong>
                </label>
                <p
                  className="text-center mb-0"
                  style={{ fontSize: "30px", color: "black" }}
                >
                  <strong>{activeUsers}</strong>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3">
            <div className="card p-4">
              <div className="bg-white p-3 rounded shadow-sm">
                <label
                  className="d-block text-center mb-2"
                  style={{ fontSize: "20px" }}
                >
                  <strong>Inactive Members</strong>
                </label>
                <p
                  className="text-center mb-0"
                  style={{ fontSize: "30px", color: "black" }}
                >
                  <strong>{inactiveUsers}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="p-3 card mt-5"
        style={{
          background: "linear-gradient(145deg, #282828, #1c1c1c)",
          width: "350px",
          height: "280px",
          borderRadius: "20px",
          boxShadow: "0 10px 20px rgba(0, 0, 0, 0.5)",
          marginLeft: "36px",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {/* Glowing Pulse Animation */}
        <div
          className="pulse-effect"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "200px",
            height: "200px",
            background: "rgba(0, 123, 255, 0.2)",
            borderRadius: "50%",
            animation: "pulse 3s infinite",
            zIndex: 1,
          }}
        ></div>

        {/* Content */}
        <div>
          {/* Top Decoration */}
          <div
            style={{
              position: "absolute",
              top: "-40px",
              right: "-40px",
              width: "100px",
              height: "100px",
              background: "linear-gradient(145deg, #007bff, #0056b3)",
              borderRadius: "50%",
              boxShadow: "0 5px 15px rgba(0, 123, 255, 0.6)",
            }}
          ></div>

          {/* Content */}
          <div
            className="referral-code-container text-center"
            style={{ zIndex: 2 }}
          >
            <h3
              style={{
                color: "#fff",
                fontSize: "22px",
                textTransform: "uppercase",
                letterSpacing: "1px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Invite Friends, Earn Money
            </h3>
            <h2
              style={{
                color: "#fff",
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "15px",
                letterSpacing: "1px",
                textShadow: "0 2px 5px rgba(0, 0, 0, 0.6)",
              }}
            >
              Your Referral Link
            </h2>
            <div
              className="input-group"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <input
                type="text"
                className="form-control"
                style={{
                  height: "50px",
                  fontSize: "16px",
                  width: "90%",
                  border: "none",
                  borderRadius: "10px",
                  boxShadow: "inset 0 3px 6px rgba(0, 0, 0, 0.3)",
                  textAlign: "center",
                }}
                value={`${referralLink}`}
                readOnly
              />
              <button
                className="btn btn-primary"
                style={{
                  width: "90%",
                  height: "45px",
                  fontSize: "16px",
                  backgroundColor: "#007bff",
                  border: "none",
                  borderRadius: "10px",
                  color: "#fff",
                  boxShadow: "0 4px 10px rgba(0, 123, 255, 0.4)",
                  transition: "transform 0.3s ease",
                }}
                onClick={handleCopyreferralLink}
                onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
              >
                {copyTooltipText}
              </button>
            </div>
          </div>
        </div>

        {/* Keyframe Animation */}
        <style>
          {`
  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.6;
    }
    50% {
      transform: translate(-50%, -50%) scale(1.2);
      opacity: 0.4;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.6;
    }
  }
`}
        </style>
      </div>

      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            {/* Main Card */}
            <div className="referral-card shadow-lg">
              <div className="referral-card-header py-4 text-center">
                <span className="header-text">Total Referrals</span>
              </div>

              {/* Referral Table */}
              <div className="referral-card-body p-4">
                {error ? (
                  <p className="text-danger text-center">{error}</p>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Username</th>
                          <th>Email</th>
                          <th>Package</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentReferrals.length > 0 ? (
                          currentReferrals.map((referral, index) => (
                            <tr key={index} className="referral-row">
                              <td>{index + 1}</td>
                              <td>{referral.username}</td>
                              <td>{referral.email}</td>
                              <td>{referral.packageName || "No Package"}</td>
                              <td
                                className={`status ${
                                  referral.packageStatus === "Active"
                                    ? "active-status"
                                    : "inactive-status"
                                }`}
                              >
                                {referral.packageStatus || "Inactive"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No referrals available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              {/* Pagination */}
              <div className="referral-pagination text-center py-3">
                {[...Array(totalPages)].map((_, pageIndex) => (
                  <button
                    key={pageIndex}
                    className={`pagination-btn ${
                      currentPage === pageIndex + 1 ? "active" : ""
                    }`}
                    onClick={() => handlePageChange(pageIndex + 1)}
                  >
                    {pageIndex + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Animations and Styling */}
        <style>
          {`
          .referral-card {
            background: linear-gradient(135deg, #34495e, #2c3e50);
            border-radius: 15px;
            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
            animation: fadeIn 0.8s ease-out;
            overflow: hidden;
          }

          .referral-card-header {
            background: linear-gradient(135deg, #16a085, #1abc9c);
            color: #fff;
            font-size: 24px;
            font-weight: bold;
            text-transform: uppercase;
            padding: 15px 0;
          }

          .header-text {
            display: inline-block;
            animation: pulse 2s infinite;
          }

          .referral-card-body {
            background: #f8f9fa;
            border-radius: 0 0 15px 15px;
          }

          @keyframes fadeIn {
            0% {
              opacity: 0;
              transform: scale(0.9);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }

          @keyframes pulse {
            0%, 100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.1);
            }
          }

          .referral-row {
            transition: all 0.3s ease-in-out;
          }

          .referral-row:hover {
            background: #e1f5fe;
            transform: translateX(10px);
          }

          .status {
            padding: 5px 10px;
            border-radius: 5px;
            font-weight: bold;
            text-align: center;
          }

          .active-status {
            background-color: #27ae60;
            color: #fff;
          }

          .inactive-status {
            background-color: #e74c3c;
            color: #fff;
          }

          .pagination-btn {
            margin: 0 5px;
            padding: 10px 15px;
            background: #34495e;
            color: #fff;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease;
          }

          .pagination-btn.active {
            background: #2980b9;
          }

          .pagination-btn:hover {
            background: #16a085;
            transform: scale(1.1);
          }
        `}
        </style>
      </div>
      <div className="alert alert-secondary text-center" role="alert">
        <p className="welcome-text">
          <strong className="heading">
            Welcome to <span className="earn-tube">EᵃʳⁿTᵘᵇᵉ💲!</span>
          </strong>
          Here, you have the opportunity to earn unlimited money and build a
          better financial future. With a variety of exciting ways to earn,{" "}
          <strong>EarnTube</strong> is the ultimate platform to start making
          money online. Whether you're interested in watching ads, building a
          team, or taking advantage of generous bonuses, we've got it all. Plus,
          we offer a special <span className="highlight">Sunday Offer</span>{" "}
          that gives you even more opportunities to earn, making this platform
          perfect for both short-term gains and long-term financial success.
        </p>

        <h2 className="section-heading">How You Can Earn on EarnTube:</h2>
        <section className="earning-methods">
          <h3 className="method-heading">
            1. Short-Term Tasks for Quick Rewards
          </h3>
          <p className="method-description">
            Looking for a quick way to earn money? Our{" "}
            <strong>Short-Term Tasks</strong> offer a fast and effective way to
            make money by completing simple activities like watching short ads
            or engaging in small tasks. These tasks provide{" "}
            <strong>instant payouts</strong>, allowing you to earn whenever you
            have a few free minutes.
          </p>

          <h3 className="method-heading">
            2. Long-Term Earnings through Packages
          </h3>
          <p className="method-description">
            For those who prefer a more consistent income stream,{" "}
            <strong>Long-Term Packages</strong> are available. By choosing one
            of our long-term earning packages, you commit to earning money over
            time by completing tasks that accumulate. This option is great for
            setting up a steady and reliable source of income.
          </p>

          <h3 className="method-heading">
            3. Daily Tasks for Consistent Earnings
          </h3>
          <p className="method-description">
            Stay active and earn even more with our <strong>Daily Tasks</strong>
            . From watching videos to rating ads, our task system ensures
            constant engagement and earnings. Complete more tasks, unlock
            rewards, and increase your earning potential!
          </p>

          <h3 className="method-heading">4. Lucky Spin for Extra Rewards</h3>
          <p className="method-description">
            Feeling lucky? <strong>EarnTube</strong> offers an exciting{" "}
            <strong>Lucky Spin</strong> feature where you can win amazing
            rewards like bonus earnings, discounts on packages, and other
            surprises.
          </p>
        </section>

        <h2 className="section-heading">Deposits and Withdrawals:</h2>
        <section className="deposits-withdrawals">
          <h3 className="method-heading">Deposits</h3>
          <p className="method-description">
            Start earning by depositing into your <strong>EarnTube</strong>{" "}
            account using preferred methods like bank transfers or digital
            wallets. It’s smooth and secure.
          </p>

          <h3 className="method-heading">Withdrawals</h3>
          <p className="method-description">
            Request a withdrawal anytime! Our process is fast, reliable, and
            ensures you can access your funds promptly using your preferred
            method.
          </p>
        </section>

        <p className="closing-text">
          With <strong>EarnTube</strong>, earning is simple, fun, and engaging.
          Start your journey towards financial freedom today!
        </p>
      </div>
    </Layout>
  );
};

export default TotalTeam;
