import React, { useState, useEffect } from "react";
import axios from "axios";

const LevelGet = () => {
  const [email, setEmail] = useState(""); // To store email
  const [levelDetails, setLevelDetails] = useState(null); // To store fetched level details
  const [error, setError] = useState(""); // To handle errors if any

  useEffect(() => {
    if (email) {
      axios
        .get(
          `https://backend-code-9qn4.onrender.com/api/v1/leveluser/admin-level/${email}`
        )
        .then((response) => {
          // Log the entire response to check its structure
          console.log("Response Data:", response.data);

          // Check if response structure is as expected
          if (
            response.data &&
            response.data.user &&
            response.data.user.levelId
          ) {
            setLevelDetails(response.data.user.levelId); // Set levelId as levelDetails
          } else {
            setError("No level details found for this user.");
            setLevelDetails(null); // Reset level details if not found
          }
        })
        .catch((error) => {
          console.error("Error fetching level details:", error);
          setError("Error fetching level details.");
        });
    }
  }, [email]); // Dependency array ensures the effect runs when email changes

  return (
    <div>
      <h2>Get User Level</h2>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email"
      />
      {error && <p className="text-danger">{error}</p>}
      {levelDetails ? (
        <div>
          <h3>Level Details:</h3>
          <p>Level: {levelDetails.Level}</p>
          <p>Upgrade Commission: {levelDetails.upgradeCommision}</p>
          <p>Monthly Salary: {levelDetails.MonthlySalary}</p>
          <p>Minimum Amount: {levelDetails.MinAmount}</p>
          <p>Minimum Active Refs: {levelDetails.MinActiveRef}</p>
          <p>Currency: {levelDetails.currency}</p>
          <p>Salary Days: {levelDetails.salarydays}</p>
          {/* Add more level details as needed */}
        </div>
      ) : (
        <p>No level details found.</p>
      )}
    </div>
  );
};

export default LevelGet;
