import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import AdminComponent from "../../Componet/Layout/AdminComponent";

const LevelCreate = () => {
  const [levels, setLevels] = useState([]);
  const [formData, setFormData] = useState({
    Level: "",
    upgradeCommision: "",
    MonthlySalary: "",
    MinAmount: "",
    MinActiveRef: "",
    currency: "USD",
    salarydays: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editLevel, setEditLevel] = useState(null);

  useEffect(() => {
    fetchLevels();
  }, []);

  const fetchLevels = async () => {
    try {
      const response = await axios.get(
        "https://backend-code-9qn4.onrender.com/api/v1/level/get"
      );
      setLevels(response.data.data);
    } catch (error) {
      console.error("Error fetching levels:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation for all fields, especially numeric ones like salarydays, MonthlySalary, MinAmount, etc.
    if (
      !formData.Level ||
      !formData.upgradeCommision ||
      !formData.MonthlySalary ||
      !formData.MinAmount ||
      !formData.MinActiveRef ||
      !formData.salarydays
    ) {
      alert("All fields are required.");
      return;
    }

    try {
      if (editLevel) {
        await axios.put(
          `https://backend-code-9qn4.onrender.com/api/v1/level/update/${editLevel._id}`,
          formData
        );
        alert("Level updated successfully!");
      } else {
        await axios.post(
          "https://backend-code-9qn4.onrender.com/api/v1/level/create",
          formData
        );
        alert("Level created successfully!");
      }
      resetForm();
      fetchLevels();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://backend-code-9qn4.onrender.com/api/v1/level/delete/${id}`
      );
      alert("Level deleted successfully!");
      fetchLevels();
    } catch (error) {
      console.error("Error deleting level:", error);
    }
  };

  const openModalForEdit = (level) => {
    setEditLevel(level);
    setFormData({
      Level: level.Level,
      upgradeCommision: level.upgradeCommision,
      MonthlySalary: level.MonthlySalary,
      MinAmount: level.MinAmount,
      MinActiveRef: level.MinActiveRef,
      currency: level.currency || "USD",
      salarydays: level.salarydays,
    });
    setModalIsOpen(true);
  };

  const resetForm = () => {
    setFormData({
      Level: "",
      upgradeCommision: "",
      MonthlySalary: "",
      MinAmount: "",
      MinActiveRef: "",
      currency: "USD",
      salarydays: "",
    });
    setModalIsOpen(false);
    setEditLevel(null);
  };

  const styles = {
    container: {
      padding: "20px",
      maxWidth: "900px",
      margin: "40px auto",
      fontFamily: "Arial, sans-serif",
      borderRadius: "10px",
      backgroundColor: "#2C3E50",
      color: "#ECF0F1",
      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
    },
    heading: { textAlign: "center", fontSize: "28px", color: "#ECF0F1" },
    form: { backgroundColor: "#34495E", padding: "20px", borderRadius: "10px" },
    input: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      borderRadius: "5px",
      border: "1px solid #ddd",
    },
    button: {
      padding: "10px 20px",
      backgroundColor: "#2980B9",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    list: { display: "flex", flexWrap: "wrap", gap: "20px", marginTop: "20px" },
    card: {
      backgroundColor: "#34495E",
      color: "#ECF0F1",
      padding: "20px",
      borderRadius: "8px",
      width: "250px",
    },
    actions: { marginTop: "10px" },
    modal: { content: { backgroundColor: "#34495E", color: "#ECF0F1" } },
  };

  return (
    <>
      <AdminComponent />
      <div style={styles.container}>
        <h1 style={styles.heading}>Level Management</h1>

        <form style={styles.form} onSubmit={handleSubmit}>
          <input
            style={styles.input}
            type="text"
            placeholder="Level"
            value={formData.Level}
            onChange={(e) =>
              setFormData({ ...formData, Level: e.target.value })
            }
            required
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Upgrade Commission"
            value={formData.upgradeCommision}
            onChange={(e) =>
              setFormData({ ...formData, upgradeCommision: e.target.value })
            }
            required
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Monthly Salary"
            value={formData.MonthlySalary}
            onChange={(e) =>
              setFormData({ ...formData, MonthlySalary: e.target.value })
            }
            required
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Min Amount"
            value={formData.MinAmount}
            onChange={(e) =>
              setFormData({ ...formData, MinAmount: e.target.value })
            }
            required
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Salary Days"
            value={formData.salarydays}
            onChange={(e) =>
              setFormData({ ...formData, salarydays: e.target.value })
            }
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Min Active Ref"
            value={formData.MinActiveRef}
            onChange={(e) =>
              setFormData({ ...formData, MinActiveRef: e.target.value })
            }
            required
          />
          <select
            style={styles.input}
            value={formData.currency}
            onChange={(e) =>
              setFormData({ ...formData, currency: e.target.value })
            }
          >
            <option value="USD">USD</option>
            <option value="PKR">PKR</option>
          </select>
          <button style={styles.button} type="submit">
            {editLevel ? "Update Level" : "Create Level"}
          </button>
        </form>

        <div style={styles.list}>
          {levels.map((level) => (
            <div style={styles.card} key={level._id}>
              <h3>{level.Level}</h3>
              <p>Upgrade Commission: {level.upgradeCommision}</p>
              <p>Monthly Salary: {level.MonthlySalary}</p>
              <p>Min Amount: {level.MinAmount}</p>
              <p>Min Active Ref: {level.MinActiveRef}</p>
              <p>Currency: {level.currency}</p>
              <p>Salary day: {level.salarydays}</p>

              <div style={styles.actions}>
                <button
                  style={styles.button}
                  onClick={() => openModalForEdit(level)}
                >
                  Edit
                </button>
                <button
                  style={{ ...styles.button, backgroundColor: "#e74c3c" }}
                  onClick={() => handleDelete(level._id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={styles.modal}
      >
        <h2>{editLevel ? "Edit Level" : "Create Level"}</h2>
        <form onSubmit={handleSubmit}>
          <input
            style={styles.input}
            type="text"
            placeholder="Level"
            value={formData.Level}
            onChange={(e) =>
              setFormData({ ...formData, Level: e.target.value })
            }
            required
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Upgrade Commission"
            value={formData.upgradeCommision}
            onChange={(e) =>
              setFormData({ ...formData, upgradeCommision: e.target.value })
            }
            required
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Monthly Salary"
            value={formData.MonthlySalary}
            onChange={(e) =>
              setFormData({ ...formData, MonthlySalary: e.target.value })
            }
            required
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Min Amount"
            value={formData.MinAmount}
            onChange={(e) =>
              setFormData({ ...formData, MinAmount: e.target.value })
            }
            required
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Salary Days"
            value={formData.salarydays}
            onChange={(e) =>
              setFormData({ ...formData, salarydays: e.target.value })
            }
          />

          <input
            style={styles.input}
            type="text"
            placeholder="Min Active Ref"
            value={formData.MinActiveRef}
            onChange={(e) =>
              setFormData({ ...formData, MinActiveRef: e.target.value })
            }
            required
          />
          <select
            style={styles.input}
            value={formData.currency}
            onChange={(e) =>
              setFormData({ ...formData, currency: e.target.value })
            }
          >
            <option value="USD">USD</option>
            <option value="PKR">PKR</option>
          </select>
          <button style={styles.button} type="submit">
            {editLevel ? "Update Level" : "Create Level"}
          </button>
          {/* Add other fields here */}
          <button style={styles.button} type="submit">
            Save
          </button>
        </form>
      </Modal>
    </>
  );
};

export default LevelCreate;
