import React, { useState, useEffect } from "react";
import axios from "axios";
import backgroundImage from "../../Assets/20255.jpg";
import { useNavigate } from "react-router-dom";

const FreeBonus = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    CurrentPackage: "",
    WorkingMonth: "",
    TotalDepositTime: "",
    TotalActiveRef: "",
    AccountMethod: "",
    AccountName: "",
    AccountMumber: "",
  });

  const [user, setUser] = useState(null);
  const [bonus, setBonus] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0); // Timer state

  useEffect(() => {
    // Fetch user details
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/bonus/getbonus"
        );
        setUser(response.data.user);
        setBonus(response.data.bonus);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();

    // Set the target date for the timer (1st January 2025)
    const targetDate = new Date("2025-01-01T00:00:00").getTime();

    // Update the timer every second
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;
      setTimeLeft(distance);

      // If the countdown is over, stop the timer
      if (distance <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://backend-code-9qn4.onrender.com/api/v1/bonus/create",
        formData
      );
      if (response.status === 201) {
        setIsSubmitted(true);
        navigate("/dashboard/user/useraccount");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const sharedContainerStyle = {
    padding: "20px",
    borderRadius: "12px",
    width: "100%",
    maxWidth: "700px",
    margin: "0 auto",
    position: "relative",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#000",
    fontWeight: "bold",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)",
  };

  if (bonus) {
    return (
      <div style={sharedContainerStyle}>
        <h2 style={{ fontWeight: "bold", color: "black", fontSize: "20px" }}>
          Your Bonus Record
        </h2>
        <p style={{ fontWeight: "bold", color: "black", fontSize: "20px" }}>
          Email: {user?.email}
        </p>
        <p style={{ fontWeight: "bold", color: "black", fontSize: "20px" }}>
          Current Package: {bonus.CurrentPackage}
        </p>
        <p style={{ fontWeight: "bold", color: "black", fontSize: "20px" }}>
          Working Month: {bonus.WorkingMonth}
        </p>
        <p style={{ fontWeight: "bold", color: "black", fontSize: "20px" }}>
          Total Deposit Time: {bonus.TotalDepositTime}
        </p>
        <p style={{ fontWeight: "bold", color: "black", fontSize: "20px" }}>
          Total Active Referrals: {bonus.TotalActiveRef}
        </p>
        <p style={{ fontWeight: "bold", color: "black", fontSize: "20px" }}>
          Account Method: {bonus.AccountMethod}
        </p>
        <p style={{ fontWeight: "bold", color: "black", fontSize: "20px" }}>
          Account Name: {bonus.AccountName}
        </p>
        <p style={{ fontWeight: "bold", color: "black", fontSize: "20px" }}>
          Account Number: {bonus.AccountMumber}
        </p>
        <p style={{ fontWeight: "bold", color: "black", fontSize: "20px" }}>
          Message: {bonus.Message}
        </p>
        {/* Other bonus details */}
      </div>
    );
  }

  return (
    <>
      <p
        style={{
          fontWeight: "bold",
          fontSize: "20px",
          backgroundColor: "pink",
          color: "black",
        }}
      >
        <marquee>
          "🎉 Get your Free Bonus Now! 🎉 Please enter the correct details below
          to claim your bonus! 💸 ❗ Note: Providing incorrect details may
          result in the forfeiture of your free bonus! ❗"{" "}
        </marquee>
      </p>
      <div style={sharedContainerStyle}>
        <h2>Free Bonus</h2>

        {user && <p>User Email: {user.email}</p>}

        <form onSubmit={handleSubmit} style={sharedContainerStyle}>
          {Object.keys(formData).map((key) => (
            <div key={key} style={{ display: "flex", flexDirection: "column" }}>
              <label>{key.replace(/([A-Z])/g, " $1")}</label>
              <input
                type="text"
                name={key}
                value={formData[key]}
                onChange={handleChange}
                required
                style={{
                  padding: "12px",
                  fontSize: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                }}
              />
            </div>
          ))}
          <div>
            <label>Message</label>
            <textarea
              name="Message"
              value={formData.Message}
              onChange={handleChange}
              required
              placeholder="Type your message for Earntube Authority"
              style={{
                padding: "12px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                height: "120px",
              }}
            />
          </div>
          <div>
            <p style={{ color: "black", fontSize: "26px", fontWeight: "bold" }}>
              Submitting in : {formatTime(timeLeft)}
            </p>
            <button
              type="submit"
              disabled={timeLeft > 0 || isSubmitted}
              style={{
                padding: "12px 20px",
                backgroundColor: "#000",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                cursor: isSubmitted || timeLeft > 0 ? "not-allowed" : "pointer",
              }}
            >
              {isSubmitted ? "Submitted" : "Submit Bonus"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default FreeBonus;
