import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/auth";
import axios from "axios";
import AdminComponent from "../../Componet/Layout/AdminComponent";
import Spinner from "../../Componet/Spinner";

const FreeBonusUser = () => {
  const [auth] = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Add search term state

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/bonus/admin/bonus"
        );
        console.log(response.data); // Log the response data

        // Set the fetched bonus data to the transactions state
        setTransactions(response.data.bonuses);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch bonus data.");
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  // Filter the transactions based on the search term (email)
  const filteredTransactions = transactions.filter((tx) =>
    tx.user?.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="dashboard-container bg-light">
        <img
          src="images/spinnerlogo.jpeg"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          alt="Loading..."
        />
        <div className="right-content">
          <h1>All Transactions</h1>
          <p>
            <span style={{ color: "#e0c10c", fontWeight: "bold" }}>
              👨{auth?.user?.username}
            </span>
            !
          </p>
        </div>
      </div>

      <div className="container-fluid m-3 p-3 w-100">
        <div className="row">
          <div className="col-md-3">
            <AdminComponent />
          </div>
          <div className="col-md-8">
            <div className="w-100 p-3">
              <div className="w-100 p-3 bg-dark text-white mb-3 p-5 w-100">
                <h1>All Free Bonus Data</h1>
              </div>

              {/* Add Search Bar */}
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by email"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              {loading ? (
                <Spinner />
              ) : error ? (
                <p>{error}</p>
              ) : (
                <div className="table-responsive">
                  <table className="table table-dark table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Email</th>
                        <th>Package</th>
                        <th>Working Month</th>
                        <th>Total Deposit Time</th>
                        <th>Total Active Referrals</th>
                        <th>Account Method</th>
                        <th>Account Name</th>
                        <th>Account Number</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTransactions.map((tx, index) => (
                        <tr key={tx._id}>
                          <td>{index + 1}</td>
                          <td>{tx.user?.email || "N/A"}</td>
                          <td>{tx.CurrentPackage || "N/A"}</td>
                          <td>{tx.WorkingMonth || "N/A"}</td>
                          <td>{tx.TotalDepositTime || "N/A"}</td>
                          <td>{tx.TotalActiveRef || "N/A"}</td>
                          <td>{tx.AccountMethod || "N/A"}</td>
                          <td>{tx.AccountName || "N/A"}</td>
                          <td>{tx.AccountMumber || "N/A"}</td>
                          <td>{tx.Message || "N/A"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeBonusUser;
