import React, { useState } from "react";
import axios from "axios";

const LongEarnUserFetch = () => {
  const [email, setEmail] = useState(""); // User email input
  const [userDetails, setUserDetails] = useState(null); // User details state
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  const handleSearch = async () => {
    if (!email) {
      setError("Please enter an email.");
      return;
    }

    setLoading(true); // Start loading
    setError(null); // Clear previous errors

    try {
      // API call to fetch user details by email
      const response = await axios.get(
        `https://backend-code-9qn4.onrender.com/api/v1/userlongearn/user-details/${email}`,
        {
          headers: {
            // Add authentication token if required
            // "Authorization": "Bearer <your-jwt-token>"
          },
        }
      );

      setUserDetails(response.data.userDetails.user); // Save user data to state
    } catch (err) {
      setError("Failed to fetch user details. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return "Invalid Date";
    }
    return date.toLocaleString(); // Format date to a readable format
  };

  return (
    <div>
      <h2>Search User Long Earn Details</h2>
      <input
        type="email"
        placeholder="Enter user email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleSearch}>Search</button>
      {loading && <p>Loading...</p>} {/* Show loading text while fetching */}
      {error && <p style={{ color: "red" }}>{error}</p>}{" "}
      {/* Show error if any */}
      {userDetails && (
        <div>
          <h3>User Details</h3>
          <p>
            <strong>Email:</strong> {userDetails.email}
          </p>
          <p>
            <strong>Total Earnings:</strong> {userDetails.totalEarnings}
          </p>
          <p>
            <strong>Total Ads Submitted:</strong>{" "}
            {userDetails.totalAdsSubmitted}
          </p>

          <h4>Ads Details:</h4>
          <ul>
            {userDetails.ads && userDetails.ads.length > 0 ? (
              userDetails.ads.map((ad, index) => (
                <li key={index}>
                  <strong>Earned:</strong> {ad.earnMoney} <br />
                  <strong>Submitted At:</strong> {formatDate(ad.submittedAt)}{" "}
                  {/* Format date */}
                </li>
              ))
            ) : (
              <p>No ads found.</p>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LongEarnUserFetch;
