import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/auth";
import logo from "../../Assets/sitelogo.png";
import backgroundImage from "../../Assets/20255.jpg";

import axios from "axios";
import Spinner from "../../Componet/Spinner";
import Layout from "../../Componet/Layout/Layout";
import "../../Styles/WithdrawalHistory.css";

const WithdrawalHistory = () => {
  const [auth] = useAuth();
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [earnings, setEarnings] = useState(null);
  const [referrals, setReferrals] = useState([]);

  const currencySymbol = () => {
    if (!earnings?.currency || earnings.currency === "") {
      return "Rs"; // Default to Rs if currency is null, undefined, or empty
    } else if (earnings.currency === "PKR") {
      return "Rs";
    } else if (earnings.currency === "USD") {
      return "$";
    } else {
      return ""; // Default case for unsupported currencies
    }
  };

  const displayCurrency = currencySymbol();
  useEffect(() => {
    const fetchUserEarnings = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/users/earnings",
          {}
        );
        setEarnings(response.data);
      } finally {
        setLoading(false);
      }
    };

    fetchUserEarnings();
  }, []);

  useEffect(() => {
    const fetchWithdrawals = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/userwithdrawal/get-all-single-withdrawal",
          {}
        );
        console.log(response.data); // Log the response data

        // Ensure response.data.withdrawals is defined
        if (response.data && response.data.withdrawals) {
          // Sort the withdrawals by createdAt date in descending order
          const sortedWithdrawals = response.data.withdrawals.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setWithdrawals(sortedWithdrawals);
        } else {
          setError("No withdrawals found");
        }
      } catch (error) {
        setError("");
      } finally {
        setLoading(false);
      }
    };

    fetchWithdrawals();
  }, [auth.token]);
  const formatAmount = (amount) => {
    return parseFloat(amount)
      .toFixed(4)
      .replace(/(\.0+|(?<=\.\d)0+)$/, "");
  };
  //next page logic
  const [currentPage, setCurrentPage] = useState(1);
  const referralsPerPage = 10;

  const totalPages = Math.ceil(referrals.length / referralsPerPage);
  const startIndex = (currentPage - 1) * referralsPerPage;
  const currentReferrals = referrals.slice(
    startIndex,
    startIndex + referralsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const rowsPerPage = 10;

  // Pagination Logic
  const totalPage = Math.ceil(withdrawals.length / rowsPerPage);
  const currentWithdrawals = withdrawals.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <Layout>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div
            className="dashboard-container"
            style={{
              padding: "40px",
              borderRadius: "20px",
              boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)",
              textAlign: "center",
              maxWidth: "750px",
              margin: "100px auto",
              position: "relative",
              overflow: "hidden",
              color: "white",
              backgroundImage: `url(${backgroundImage})`, // Correctly imported image
              backgroundSize: "cover", // Adjusted to fit the card properly
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* Main Content */}
            <div style={{ position: "relative", zIndex: 2 }}>
              <h1
                style={{
                  fontSize: "42px",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  color: "black",
                  marginBottom: "20px",
                }}
              >
                CASH HISTORY
              </h1>

              <div className="user-info" style={{ marginTop: "30px" }}>
                <img
                  src={logo}
                  alt="Dashboard Logo"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    border: "4px solid #00ffff",
                    boxShadow: "0px 5px 20px rgba(0, 255, 255, 0.5)",
                  }}
                />

                <div
                  className="info-box"
                  style={{
                    marginTop: "20px",
                    padding: "20px",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "15px",
                    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <p
                    style={{
                      fontSize: "24px",
                      marginBottom: "10px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    👨 {auth?.user?.username}
                  </p>
                  <p style={{ fontSize: "18px", marginBottom: "5px" }}>
                    <strong
                      style={{
                        color: "#000",

                        padding: "10px",
                      }}
                    >
                      Earnings:
                    </strong>{" "}
                    {loading ? (
                      <span>Loading...</span>
                    ) : (
                      <span
                        style={{
                          color: "#000",

                          padding: "10px",
                        }}
                      >
                        {formatAmount(earnings ? earnings.earnings : "0")}{" "}
                        {displayCurrency}
                      </span>
                    )}
                  </p>
                  <p style={{ fontSize: "18px" }}>
                    <strong
                      style={{
                        color: "#000",

                        padding: "10px",
                      }}
                    >
                      Total Earnings:
                    </strong>{" "}
                    {loading ? (
                      <span>Loading...</span>
                    ) : (
                      <span
                        style={{
                          color: "#000",

                          padding: "10px",
                        }}
                      >
                        {formatAmount(earnings ? earnings.totalEarnings : "0")}{" "}
                        {displayCurrency}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container my-5">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10">
                {/* Main Card */}
                <div className="withdrawal-card shadow-lg">
                  <div className="withdrawal-card-header text-center">
                    <h2>All Withdrawals</h2>
                  </div>
                  <div className="withdrawal-card-body">
                    {error ? (
                      <p className="text-danger text-center">{error}</p>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-hover table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Account Name</th>
                              <th>Account Number</th>
                              <th>Amount</th>
                              <th>Payment Method</th>
                              <th>Applied Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentWithdrawals.length > 0 ? (
                              currentWithdrawals.map((tx, index) => (
                                <tr key={tx._id} className="withdrawal-row">
                                  <td>
                                    {(currentPage - 1) * rowsPerPage +
                                      index +
                                      1}
                                  </td>
                                  <td>{tx.accountName}</td>
                                  <td>{tx.accountNumber}</td>
                                  <td>
                                    {tx.amount} {displayCurrency}
                                  </td>
                                  <td>{tx.paymentMethod?.method || "N/A"}</td>
                                  <td>
                                    {new Date(
                                      tx.createdAt
                                    ).toLocaleDateString()}
                                  </td>
                                  <td>
                                    <span
                                      className={`status-badge ${
                                        tx.status === "approved"
                                          ? "approved"
                                          : tx.status === "rejected"
                                          ? "rejected"
                                          : tx.status === "processing"
                                          ? "processing"
                                          : "pending"
                                      }`}
                                    >
                                      {tx.status.charAt(0).toUpperCase() +
                                        tx.status.slice(1)}
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center">
                                  No withdrawals found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {/* Pagination */}
                    {totalPage > 1 && (
                      <div className="pagination">
                        {Array.from({ length: totalPages }, (_, i) => (
                          <button
                            key={i}
                            className={`page-btn ${
                              currentPage === i + 1 ? "active" : ""
                            }`}
                            onClick={() => handlePageChange(i + 1)}
                          >
                            {i + 1}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* CSS Styling */}
            <style>
              {`
          .withdrawal-card {
            background-color: #34495e;
            border-radius: 15px;
            overflow: hidden;
            animation: fadeIn 0.6s ease-out;
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(-10px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          .withdrawal-card-header {
            background: linear-gradient(90deg, #6a11cb, #2575fc);
            color: white;
            padding: 20px;
            font-size: 24px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 2px;
            border-bottom: 4px solid #34495e;
          }

          .withdrawal-card-body {
            padding: 20px;
            background-color: #ecf0f1;
          }

          .withdrawal-row:hover {
            background-color: #eaf2f8;
            transition: background 0.3s ease, transform 0.2s ease;
            transform: translateX(5px);
          }

          .status-badge {
            padding: 5px 10px;
            border-radius: 12px;
            color: white;
            font-size: 14px;
            font-weight: bold;
            text-transform: capitalize;
          }

          .status-badge.approved {
            background-color: #27ae60;
          }

          .status-badge.rejected {
            background-color: #e74c3c;
          }

          .status-badge.processing {
            background-color: #f39c12;
          }

          .status-badge.pending {
            background-color: #7f8c8d;
          }

          .pagination {
            margin-top: 20px;
            text-align: center;
          }

          .page-btn {
            margin: 0 5px;
            padding: 5px 15px;
            border: none;
            border-radius: 5px;
            background: #6a11cb;
            color: white;
            cursor: pointer;
            font-size: 14px;
            transition: all 0.3s ease;
          }

          .page-btn:hover {
            background: #2575fc;
          }

          .page-btn.active {
            background: #34495e;
            color: #ecf0f1;
          }
        `}
            </style>
          </div>
        </>
      )}
      <div className="alert alert-secondary text-center" role="alert">
        <p className="welcome-text">
          <strong className="heading">
            Welcome to <span className="earn-tube">EᵃʳⁿTᵘᵇᵉ💲!</span>
          </strong>
          Here, you have the opportunity to earn unlimited money and build a
          better financial future. With a variety of exciting ways to earn,{" "}
          <strong>EarnTube</strong> is the ultimate platform to start making
          money online. Whether you're interested in watching ads, building a
          team, or taking advantage of generous bonuses, we've got it all. Plus,
          we offer a special <span className="highlight">Sunday Offer</span>{" "}
          that gives you even more opportunities to earn, making this platform
          perfect for both short-term gains and long-term financial success.
        </p>

        <h2 className="section-heading">How You Can Earn on EarnTube:</h2>
        <section className="earning-methods">
          <h3 className="method-heading">
            1. Short-Term Tasks for Quick Rewards
          </h3>
          <p className="method-description">
            Looking for a quick way to earn money? Our{" "}
            <strong>Short-Term Tasks</strong> offer a fast and effective way to
            make money by completing simple activities like watching short ads
            or engaging in small tasks. These tasks provide{" "}
            <strong>instant payouts</strong>, allowing you to earn whenever you
            have a few free minutes.
          </p>

          <h3 className="method-heading">
            2. Long-Term Earnings through Packages
          </h3>
          <p className="method-description">
            For those who prefer a more consistent income stream,{" "}
            <strong>Long-Term Packages</strong> are available. By choosing one
            of our long-term earning packages, you commit to earning money over
            time by completing tasks that accumulate. This option is great for
            setting up a steady and reliable source of income.
          </p>

          <h3 className="method-heading">
            3. Daily Tasks for Consistent Earnings
          </h3>
          <p className="method-description">
            Stay active and earn even more with our <strong>Daily Tasks</strong>
            . From watching videos to rating ads, our task system ensures
            constant engagement and earnings. Complete more tasks, unlock
            rewards, and increase your earning potential!
          </p>

          <h3 className="method-heading">4. Lucky Spin for Extra Rewards</h3>
          <p className="method-description">
            Feeling lucky? <strong>EarnTube</strong> offers an exciting{" "}
            <strong>Lucky Spin</strong> feature where you can win amazing
            rewards like bonus earnings, discounts on packages, and other
            surprises.
          </p>
        </section>

        <h2 className="section-heading">Deposits and Withdrawals:</h2>
        <section className="deposits-withdrawals">
          <h3 className="method-heading">Deposits</h3>
          <p className="method-description">
            Start earning by depositing into your <strong>EarnTube</strong>{" "}
            account using preferred methods like bank transfers or digital
            wallets. It’s smooth and secure.
          </p>

          <h3 className="method-heading">Withdrawals</h3>
          <p className="method-description">
            Request a withdrawal anytime! Our process is fast, reliable, and
            ensures you can access your funds promptly using your preferred
            method.
          </p>
        </section>

        <p className="closing-text">
          With <strong>EarnTube</strong>, earning is simple, fun, and engaging.
          Start your journey towards financial freedom today!
        </p>
      </div>
    </Layout>
  );
};

export default WithdrawalHistory;
