import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Verification = () => {
  const [formData, setFormData] = useState({
    CNIC: "",
    Name: "",
    phoneNo: "",
  });

  const [verificationStatus, setVerificationStatus] = useState(null);
  const [recordExists, setRecordExists] = useState(false); // Check if user record exists
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Fetch verification data when component loads
  useEffect(() => {
    const fetchVerificationData = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/verify/verified-user"
        );
        const verification = response.data.verification;

        if (verification) {
          // User record exists
          setRecordExists(true);
          setFormData({
            CNIC: verification.CNIC || "",
            Name: verification.Name || "",
            phoneNo: verification.phoneNo || "",
          });
          setVerificationStatus(verification.verification);
        } else {
          // No record found
          setRecordExists(false);
        }
      } catch (error) {
        console.error("Error fetching verification data:", error);
        setErrorMessage("");
      }
    };

    fetchVerificationData();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (recordExists) {
      return;
    }

    setErrorMessage("");

    try {
      const response = await axios.post(
        "https://backend-code-9qn4.onrender.com/api/v1/verify/submit",
        formData
      );

      console.log("Response:", response.data);
      navigate("/dashboard/user/useraccount");
    } catch (error) {
      console.error("Error submitting verification:", error);
      setErrorMessage(
        "Your previous verification was rejected. Please use another account"
      );
    }
    let answer = window.prompt(
      "Its verify in working one day and some time take more time. Make sure your detail is correct!!    Please enter YES/OK for next"
    );
    if (!answer) return;
  };

  return (
    <div className="verification-container">
      <div className="profile-header">
        <div className="profile-photo">
          <div style={{ width: "100px", height: "100px" }}>👨🏻</div>
        </div>
        <h2 className="title">Verification Form</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {recordExists && (
          <p className="status-message">
            Current Status:{" "}
            <strong>{verificationStatus || "Not Verified"}</strong>
          </p>
        )}
        <marquee>
          <p
            style={{
              backgroundColor: "pink",
              color: "black",
              fontWeight: "bold",
              padding: 10,
              fontSize: 20,
            }}
          >
            Please enter your valid and full detail. In the case of wrong detail
            you lose your account
          </p>
        </marquee>
      </div>

      <form onSubmit={handleSubmit} className="verification-form">
        <div className="form-group">
          <label htmlFor="Name">Full Name</label>
          <input
            type="text"
            id="Name"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Enter your full name"
            required
            disabled={recordExists} // Disable if record exists
          />
        </div>

        <div className="form-group">
          <label htmlFor="CNIC">CNIC</label>
          <input
            type="text"
            id=" CNIC"
            name="CNIC"
            value={formData.CNIC}
            onChange={handleChange}
            placeholder="Enter your CNIC"
            required
            disabled={recordExists} // Disable if record exists
          />
        </div>

        <div className="form-group">
          <label htmlFor="phoneNo">Phone Number</label>
          <input
            type="text"
            id="phoneNo"
            name="phoneNo"
            value={formData.phoneNo}
            onChange={handleChange}
            placeholder="Enter your phone number"
            required
            disabled={recordExists} // Disable if record exists
          />
        </div>

        {!recordExists && (
          <button type="submit" className="submit-button">
            Submit
          </button>
        )}
      </form>

      <style jsx>{`
        body {
          margin: 0;
          padding: 0;
          font-family: "Poppins", sans-serif;
          background-color: #181818; /* Dark background */
          color: #ffffff;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .verification-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: 100%;
          width: 100%;
          padding: 20px;
        }

        .verification-form {
          background: linear-gradient(
            135deg,
            #0f0c29,
            #302b63,
            #24243e
          ); /* Gradient background */
          border-radius: 20px;
          width: 100%;
          max-width: 450px;
          padding: 40px 30px;
          box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.8);
          backdrop-filter: blur(10px); /* Adds a blurred effect */
          border: 1px solid rgba(255, 255, 255, 0.2);
          transition: all 0.3s ease-in-out;
          margin-top: 20px;
        }

        .verification-form:hover {
          box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.9); /* Hover effect */
        }

        h2.title {
          text-align: center;
          font-size: 28px;
          color: #ff4081; /* Vibrant color for title */
          margin-bottom: 20px;
          letter-spacing: 2px;
        }

        .profile-photo {
          display: block;
          background: #3f3f3f;
          padding: 30px;
          border-radius: 50%;
          font-size: 60px;
          margin-bottom: 20px;
          color: #ffffff;
          text-align: center;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
          width: 90px;
          height: 90px;
          margin-left: auto;
          margin-right: auto;
        }

        .form-group {
          margin-bottom: 25px;
        }

        label {
          font-size: 16px;
          color: #ffffff;
          margin-bottom: 10px;
          display: block;
          font-weight: 500;
        }

        input {
          width: 100%;
          padding: 12px;
          margin-top: 8px;
          border-radius: 10px;
          background: rgba(255, 255, 255, 0.1);
          border: 2px solid rgba(255, 255, 255, 0.3);
          color: #ffffff;
          font-size: 16px;
          transition: 0.3s ease;
        }

        input:focus {
          outline: none;
          border-color: #ff4081; /* Vibrant pink on focus */
          background: rgba(255, 255, 255, 0.15);
        }

        button.submit-button {
          background-color: #ff4081; /* Bold color for submit */
          color: white;
          padding: 15px 0;
          border-radius: 10px;
          width: 100%;
          border: none;
          font-size: 18px;
          cursor: pointer;
          margin-top: 20px;
          transition: 0.3s ease;
        }

        button.submit-button:hover {
          background-color: #f50057; /* Slightly darker on hover */
        }

        .error-message {
          color: #ff3d3d;
          font-size: 14px;
          margin-top: 10px;
          text-align: center;
        }

        .status-message {
          font-size: 16px;
          font-weight: 600;
          color: #00e676; /* Success message in bright green */
          margin-top: 10px;
        }

        .status-message span {
          font-weight: 400;
          color: #e0e0e0;
          display: block;
          text-align: center;
        }

        /* Responsive Styles */
        @media only screen and (max-width: 768px) {
          .verification-container {
            justify-content: flex-start;
          }

          .verification-form {
            padding: 30px;
          }

          .profile-photo {
            font-size: 50px;
          }

          h2.title {
            font-size: 24px;
          }
        }

        @media only screen and (max-width: 500px) {
          .verification-form {
            padding: 25px;
          }

          .profile-photo {
            width: 70px;
            height: 70px;
            font-size: 50px;
          }
        }
      `}</style>
    </div>
  );
};

export default Verification;
