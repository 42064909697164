import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "../../Styles/CreatePackage.css";
import AdminComponent from "../../Componet/Layout/AdminComponent";

const CreatePackage = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [earningRate, setEarningRate] = useState("");
  const [numOfAds, setNumOfAds] = useState("");
  const [discount, setDiscount] = useState("");
  const [commissionRate, setCommissionRate] = useState("");
  const [Packagecurrency, setPackagecurrency] = useState("PKR");
  const [isActive, setIsActive] = useState(true);

  const handleCreate = async (e) => {
    e.preventDefault();

    if (
      !name ||
      !description ||
      !price ||
      !duration ||
      !earningRate ||
      !numOfAds ||
      !commissionRate ||
      !Packagecurrency
    ) {
      toast.error("Please fill all the required fields.");
      return;
    }

    try {
      const packageData = {
        name,
        description,
        price,
        duration,
        earningRate,
        numOfAds,
        discount,
        commissionRate,
        Packagecurrency,
        isActive,
      };

      const { data } = await axios.post(
        "https://backend-code-9qn4.onrender.com/api/v1/package/create-package",
        packageData
      );

      if (data.success) {
        toast.success(data.message);
        navigate("/dashboard/admin/all-package");
      } else {
        toast.error(data.error || "Package creation failed");
      }

      // Reset form fields
      setName("");
      setDescription("");
      setPrice("");
      setDuration("");
      setEarningRate("");
      setNumOfAds("");
      setDiscount("");
      setCommissionRate("");
      setPackagecurrency("PKR");
      setIsActive(true);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="dashboard-container bg-light">
      <AdminComponent />
      <div className="create-package-form">
        <h2>Create New Package</h2>
        <form onSubmit={handleCreate}>
          <input
            type="text"
            value={name}
            placeholder="Package Name"
            onChange={(e) => setName(e.target.value)}
            required
          />
          <textarea
            value={description}
            placeholder="Description"
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <input
            type="number"
            value={price}
            placeholder="Price"
            onChange={(e) => setPrice(e.target.value)}
            required
          />
          <input
            type="number"
            value={duration}
            placeholder="Duration (days)"
            onChange={(e) => setDuration(e.target.value)}
            required
          />
          <input
            type="number"
            value={earningRate}
            placeholder="Earning Rate"
            onChange={(e) => setEarningRate(e.target.value)}
            required
          />
          <input
            type="number"
            value={numOfAds}
            placeholder="Number of Ads"
            onChange={(e) => setNumOfAds(e.target.value)}
            required
          />
          <input
            type="number"
            value={discount}
            placeholder="Discount (optional)"
            onChange={(e) => setDiscount(e.target.value)}
          />
          <input
            type="number"
            value={commissionRate}
            placeholder="Commission Rate"
            onChange={(e) => setCommissionRate(e.target.value)}
            required
          />
          <div className="mb-3">
            <label htmlFor="isActive" className="form-label">
              Active:
            </label>
            <select
              value={isActive ? "Active" : "Inactive"}
              className="form-control"
              onChange={(e) => setIsActive(e.target.value === "Active")}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <select
            value={Packagecurrency}
            onChange={(e) => setPackagecurrency(e.target.value)}
          >
            <option value="PKR">PKR</option>
            <option value="USD">USD</option>
          </select>
          <button type="submit">Create Package</button>
        </form>
      </div>
    </div>
  );
};

export default CreatePackage;
