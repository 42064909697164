import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "../../Context/auth";
import "../../Styles/AllPackage.css";
import AdminComponent from "../../Componet/Layout/AdminComponent";

const AllPackage = () => {
  const [usdPackages, setUsdPackages] = useState([]);
  const [pkrPackages, setPkrPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null); // State for selected package
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility
  const [auth] = useAuth();

  // Get all Packages
  const getAllPackages = async () => {
    try {
      const { data } = await axios.get(
        "https://backend-code-9qn4.onrender.com/api/v1/package/all-package"
      );
      setUsdPackages(data.usdPackages);
      setPkrPackages(data.pkrPackages);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  // Open modal with selected package details
  const openModal = (pkg) => {
    setSelectedPackage(pkg);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPackage(null);
  };

  // Update package
  const updatePackage = async (updatedPackage) => {
    try {
      await axios.put(
        `https://backend-code-9qn4.onrender.com/api/v1/package/update-package/${updatedPackage._id}`,
        updatedPackage
      );
      toast.success("Package updated successfully!");
      getAllPackages(); // Refresh the packages list
      closeModal(); // Close modal after update
    } catch (error) {
      console.error(error);
      toast.error("Failed to update package");
    }
  };

  // Delete package
  const deletePackage = async (id) => {
    try {
      await axios.delete(
        `https://backend-code-9qn4.onrender.com/api/v1/package/delete-package/${id}`
      );
      toast.success("Package deleted successfully!");
      // Refresh the packages list after deletion
      getAllPackages();
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete package");
    }
  };

  // Lifecycle method
  useEffect(() => {
    getAllPackages();
  }, []);

  return (
    <>
      <div className="dashboard-container bg-light">
        <img
          src="images/spinnerlogo.jpeg"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          alt="Loading..."
        />
        <div className="right-content">
          <h1>All Packages</h1>
          <p>
            <span style={{ color: "#e0c10c", fontWeight: "bold" }}>
              👨 {auth?.user?.username}
            </span>
            !
          </p>
        </div>
      </div>

      <div className="dashboard_container">
        <div className="row">
          <div className="col-md-3">
            <AdminComponent />
          </div>
          <div className="col-md-8">
            <h1 className="text-center text-light bg-dark py-4 mb-5 p-9">
              All Package List
            </h1>

            {/* USD Packages */}
            <h2 className="text-center">USD Packages</h2>
            <div className="row justify-content-center">
              <div className="package-list">
                {usdPackages.length === 0 ? (
                  <p className="text-center">No USD packages found</p>
                ) : (
                  usdPackages.map((p) => (
                    <div key={p._id} className="card m-2 package-card">
                      <div className="card-body">
                        <h5 className="card-title p-3 mb-3 bg-dark text-light">
                          Package Name: {p.name}
                        </h5>
                        <p className="card-text p-3 mb-3">
                          Package Description: {p.description}
                        </p>
                        <p className="card-text p-3 mb-3">
                          Package Price: {p.price} $
                        </p>
                        <p className="card-text p-3 mb-3">
                          Time Duration: {p.duration} days
                        </p>
                        <p className="card-text p-3 mb-3">
                          Earning Rate: {p.earningRate} $
                        </p>
                        <p className="card-text p-3 mb-3">
                          Total Ads: {p.numOfAds}
                        </p>
                        <p className="card-text p-3 mb-3">
                          Package Discount: {p.discount} %
                        </p>
                        <p className="card-text p-3 mb-3">
                          Package Commission: {p.commissionRate} $
                        </p>
                        <p className="card-text p-3 mb-3">
                          Status:{" "}
                          {p.isActive ? (
                            <span className="badge bg-success">Active</span>
                          ) : (
                            <span className="badge bg-danger">Inactive</span>
                          )}
                        </p>
                        <div className="d-flex justify-content-between">
                          <button
                            className="btn btn-warning"
                            onClick={() => openModal(p)} // Open modal for this package
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => deletePackage(p._id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            {/* PKR Packages */}
            <h2 className="text-center mt-5">PKR Packages</h2>
            <div className="row justify-content-center">
              <div className="package-list">
                {pkrPackages.length === 0 ? (
                  <p className="text-center">No PKR packages found</p>
                ) : (
                  pkrPackages.map((p) => (
                    <div key={p._id} className="card m-2 package-card">
                      <div className="card-body">
                        <h5 className="card-title p-3 mb-3 bg-dark text-light">
                          Package Name: {p.name}
                        </h5>
                        <p className="card-text p-3 mb-3">
                          Package Description: {p.description}
                        </p>
                        <p className="card-text p-3 mb-3">
                          Package Price: {p.price} Rs
                        </p>
                        <p className="card-text p-3 mb-3">
                          Time Duration: {p.duration} days
                        </p>
                        <p className="card-text p-3 mb-3">
                          Earning Rate: {p.earningRate} Rs
                        </p>
                        <p className="card-text p-3 mb-3">
                          Total Ads: {p.numOfAds}
                        </p>
                        <p className="card-text p-3 mb-3">
                          Package Discount: {p.discount} %
                        </p>
                        <p className="card-text p-3 mb-3">
                          Package Commission: {p.commissionRate} Rs
                        </p>
                        <p className="card-text p-3 mb-3">
                          Status:{" "}
                          {p.isActive ? (
                            <span className="badge bg-success">Active</span>
                          ) : (
                            <span className="badge bg-danger">Inactive</span>
                          )}
                        </p>
                        <div className="d-flex justify-content-between">
                          <button
                            className="btn btn-warning"
                            onClick={() => openModal(p)} // Open modal for this package
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => deletePackage(p._id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Update Package */}
      {isModalOpen && selectedPackage && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-content">
            <h3>Update Package</h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updatePackage(selectedPackage);
              }}
            >
              <div>
                <label>Package Name:</label>
                <input
                  type="text"
                  value={selectedPackage.name}
                  onChange={(e) =>
                    setSelectedPackage({
                      ...selectedPackage,
                      name: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label>Earning Rate:</label>
                <input
                  type="text"
                  value={selectedPackage.earningRate}
                  onChange={(e) =>
                    setSelectedPackage({
                      ...selectedPackage,
                      earningRate: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label>Description:</label>
                <textarea
                  value={selectedPackage.description}
                  onChange={(e) =>
                    setSelectedPackage({
                      ...selectedPackage,
                      description: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label>Price:</label>
                <input
                  type="number"
                  value={selectedPackage.price}
                  onChange={(e) =>
                    setSelectedPackage({
                      ...selectedPackage,
                      price: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label>Status:</label>
                <select
                  value={selectedPackage.isActive ? "active" : "inactive"}
                  onChange={(e) =>
                    setSelectedPackage({
                      ...selectedPackage,
                      isActive: e.target.value === "active",
                    })
                  }
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              {/* Add other fields here for updating */}
              <div>
                <button type="submit">Update Package</button>
                <button type="button" onClick={closeModal}>
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AllPackage;
