import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../../Styles/ForgetPassword.css";

const ResetPassword = () => {
  const { token } = useParams(); // Extract token from URL
  const navigate = useNavigate(); // For navigation
  const [email, setEmail] = useState(""); // Email state
  const [password, setPassword] = useState(""); // New password state
  const [confirmPassword, setConfirmPassword] = useState(""); // Confirm password state
  const [message, setMessage] = useState(""); // Error or success message
  const [loading, setLoading] = useState(false); // Loading state

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form default behavior
    setMessage(""); // Clear previous messages

    // Check if passwords match
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    setLoading(true); // Set loading to true
    try {
      // Make API request
      const response = await axios.post(
        `https://backend-code-9qn4.onrender.com/api/v1/users/request-password-reset/${token}`,
        { email, password }
      );

      // Show success message
      alert(response.data.message);

      // Redirect on success
      if (response.data.success) {
        navigate("/login"); // Navigate to login page
      }
    } catch (error) {
      // Handle errors
      const errorMsg =
        error.response?.data?.message ||
        "Error resetting password. Please try again.";
      setMessage(errorMsg); // Display error message
      console.error("Error resetting password:", error); // Log error details
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="card-front">
      <div className="center-wrap">
        <div className="section text-center">
          <h4 className="mb-4 pb-3" style={{ fontSize: "20px" }}>
            Reset Password
          </h4>

          {/* Show success or error message */}
          {message && (
            <div
              className={`alert ${
                message.includes("Error") ? "alert-danger" : "alert-success"
              }`}
            >
              {message}
            </div>
          )}

          {/* Form for resetting password */}
          <form onSubmit={handleSubmit}>
            {/* Email Field */}
            <div className="form-group mt-2">
              <input
                type="email"
                name="logemail"
                className="form-style"
                placeholder="Your Email"
                id="logemail"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <i className="input-icon uil uil-at" />
            </div>

            {/* New Password Field */}
            <div className="form-group mt-2">
              <input
                type="password"
                name="logpass"
                className="form-style"
                placeholder="New Password"
                id="logpass"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i className="input-icon uil uil-lock-alt" />
            </div>

            {/* Confirm Password Field */}
            <div className="form-group mt-2">
              <input
                type="password"
                name="logpass"
                className="form-style"
                placeholder="Confirm New Password"
                id="logpass"
                autoComplete="off"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <i className="input-icon uil uil-lock-alt" />
            </div>

            {/* Submit Button */}
            <button type="submit" className="btn mt-4" disabled={loading}>
              {loading ? "Updating..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
