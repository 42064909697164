import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../Styles/Ads.css";
import { useAuth } from "../../Context/auth";

import backgroundImage from "../../Assets/20255.jpg";
import Layout from "./../../Componet/Layout/Layout";
import logo from "../../Assets/sitelogo.png";

const LongEarnAds = () => {
  const [auth] = useAuth();

  const [ads, setAds] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [code, setCode] = useState("");
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [hasWatchedToday, setHasWatchedToday] = useState(false);
  const [timerExpired, setTimerExpired] = useState(false);
  const [earnings, setEarnings] = useState(null);

  // Overlay timer logic
  const targetDate = new Date("2025-01-01T00:00:00Z").getTime();
  const [timeRemaining, setTimeRemaining] = useState(targetDate - Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const remaining = targetDate - now;
      setTimeRemaining(remaining);
      if (remaining <= 0) {
        clearInterval(interval);
        setTimerExpired(true);
        localStorage.setItem("timerExpired", "true");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  useEffect(() => {
    const isTimerExpired = localStorage.getItem("timerExpired") === "true";
    if (isTimerExpired) {
      setTimerExpired(true);
    }
  }, []);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const { data } = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/userlongearn/user-ads"
        );
        if (data.success) {
          setAds(data.ads);
          checkWatchedAds(data.ads);
        } else {
          setMessage("No ads available.");
        }
      } catch (error) {
        setMessage("Error fetching ads.");
      }
    };

    fetchAds();
  }, []);

  const checkWatchedAds = (ads) => {
    const today = new Date().toISOString().split("T")[0];
    const watchedDate = localStorage.getItem("watchedDate");

    if (watchedDate === today) {
      const watchedIndex = parseInt(localStorage.getItem("watchedAdIndex"));
      setCurrentAdIndex(watchedIndex + 1 < ads.length ? watchedIndex + 1 : 0);
      setHasWatchedToday(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!ads[currentAdIndex] || !code || !comment) {
      setMessage("Please complete all fields.");
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.post(
        "https://backend-code-9qn4.onrender.com/api/v1/userlongearn/create",
        {
          adId: ads[currentAdIndex]._id,
          code,
          comment,
        }
      );
      setMessage(data.message);
      setCode("");
      setComment("");
      setLoading(false);
      localStorage.setItem(
        "watchedDate",
        new Date().toISOString().split("T")[0]
      );
      localStorage.setItem("watchedAdIndex", currentAdIndex);
      setHasWatchedToday(true);
    } catch (error) {
      setLoading(false);
      setMessage(error.response?.data?.message || "Error submitting ad");
    }
  };

  const handleWatchNow = () => {
    if (ads[currentAdIndex]) {
      window.open(ads[currentAdIndex].Link, "_blank");
    }
  };

  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };
  useEffect(() => {
    const fetchUserEarnings = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/users/earnings",
          {}
        );
        setEarnings(response.data);
      } catch (err) {
        setError("Failed to fetch earnings.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserEarnings();
  }, []);
  const currencySymbol = () => {
    if (!earnings?.currency || earnings.currency === "") {
      return "Rs"; // Default to Rs if currency is null, undefined, or empty
    } else if (earnings.currency === "PKR") {
      return "Rs";
    } else if (earnings.currency === "USD") {
      return "$";
    } else {
      return ""; // Default case for unsupported currencies
    }
  };

  const displayCurrency = currencySymbol();
  const formatAmount = (amount) => {
    return parseFloat(amount)
      .toFixed(4)
      .replace(/(\.0+|(?<=\.\d)0+)$/, "");
  };
  return (
    <Layout>
      <div
        className="dashboard-container"
        style={{
          padding: "40px",
          borderRadius: "20px",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)",
          textAlign: "center",
          maxWidth: "750px",
          margin: "100px auto",
          position: "relative",
          overflow: "hidden",
          color: "white",
          backgroundImage: `url(${backgroundImage})`, // Correctly imported image
          backgroundSize: "cover", // Adjusted to fit the card properly
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Main Content */}
        <div style={{ position: "relative", zIndex: 2 }}>
          <h1
            style={{
              fontSize: "42px",
              fontWeight: "700",
              textTransform: "uppercase",
              color: "black",
              marginBottom: "20px",
            }}
          >
            LongEarn
          </h1>

          <div className="user-info" style={{ marginTop: "30px" }}>
            <img
              src={logo}
              alt="Dashboard Logo"
              className="responsive-logo"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                border: "4px solid #00ffff",
                boxShadow: "0px 5px 20px rgba(0, 255, 255, 0.5)",
              }}
            />

            <div
              className="info-box"
              style={{
                marginTop: "20px",
                padding: "20px",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "15px",
                boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  color: "black",
                }}
              >
                👨 {auth?.user?.username}
              </p>
              <p style={{ fontSize: "18px", marginBottom: "5px" }}>
                <strong
                  style={{
                    color: "#000",

                    padding: "10px",
                  }}
                >
                  LongEarn:
                </strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span
                    style={{
                      color: "#000",

                      padding: "10px",
                    }}
                  >
                    {formatAmount(earnings ? earnings.earnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong
                  style={{
                    color: "#000",

                    padding: "10px",
                  }}
                >
                  Total Earnings:
                </strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span
                    style={{
                      color: "#000",

                      padding: "10px",
                    }}
                  >
                    {formatAmount(earnings ? earnings.totalEarnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="alert alert-success"
        role="alert"
        style={{
          padding: "20px",
          borderRadius: "10px",
          marginTop: "20px",
          borderRadius: "80px",
        }}
      >
        <h2
          style={{ color: "black", textAlign: "center", marginBottom: "20px" }}
        >
          How It Works
        </h2>
        <ol style={{ fontSize: "16px", lineHeight: "1.6" }}>
          <li>Click the "Watch Now" button to start.</li>
          <li>
            Watch the video carefully to find the code. The code might appear
            within the video, in the description, or in the title.
          </li>
          <li>
            Once you find the code, comment below the video using the code.
          </li>
          <li>
            Copy your comment from the video, paste it into the designated field
            on this page, and ensure it matches exactly before submitting.
          </li>
          <li>
            Submissions with incorrect codes or mismatched comments will not be
            accepted.
          </li>
          <li>You can watch only one ad at a time.</li>
        </ol>
      </div>

      {!timerExpired && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
            flexDirection: "column",
            fontFamily: "'Arial', sans-serif",
          }}
        >
          <h1
            style={{
              fontSize: "4rem",
              margin: "0",
              animation: "pulse 2s infinite",
            }}
          >
            Time Remaining
          </h1>
          <div
            style={{
              fontSize: "2.5rem",
              background: "linear-gradient(to right, #ff7e5f, #feb47b)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              animation: "fadeIn 1.5s ease-in-out",
            }}
          >
            {formatTime(timeRemaining)}
          </div>
          <style>{`
      @keyframes pulse {
        0% {
          transform: scale(1);
          opacity: 1;
        }
        50% {
          transform: scale(1.1);
          opacity: 0.8;
        }
        100% {
          transform: scale(1);
          opacity: 1;
        }
      }
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `}</style>
        </div>
      )}

      <div
        style={{
          padding: "40px",
          maxWidth: "800px",
          margin: "auto",
          background: "#f4f4f4",
          borderRadius: "20px",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
          fontFamily: "'Arial', sans-serif",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            fontSize: "3rem",
            color: "#333",
            fontWeight: "bold",
            marginBottom: "30px",
          }}
        >
          Watch Ads & Earn
        </h2>
        {loading && (
          <div style={{ textAlign: "center" }}>
            <div className="loading-spinner"></div>
          </div>
        )}
        {message && (
          <p
            style={{
              color: "red",
              textAlign: "center",
              fontSize: "1.2rem",
              marginBottom: "20px",
            }}
          >
            {message}
          </p>
        )}

        {ads.length > 0 ? (
          <div>
            <h3 style={{ textAlign: "center", fontSize: "1.8rem" }}>
              {ads[currentAdIndex]?.videotitle || "Ad Title"}
            </h3>
            {ads[currentAdIndex]?.Link ? (
              <button
                onClick={handleWatchNow}
                style={{
                  padding: "15px 25px",
                  backgroundColor: "#FF6347",
                  color: "white",
                  fontSize: "1.2rem",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                  width: "100%",
                  marginBottom: "20px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                  transition: "background-color 0.3s ease",
                }}
              >
                Watch Now
              </button>
            ) : (
              <p style={{ textAlign: "center", fontSize: "1.2rem" }}>
                Ad link not available.
              </p>
            )}

            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginTop: "20px",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(${backgroundImage})`,
              }}
            >
              <div>
                <label
                  style={{
                    fontWeight: "bold",
                    fontSize: "2rem",
                    color: "black",
                  }}
                >
                  Enter Code
                </label>
                <input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                  placeholder="Enter code"
                  style={{
                    width: "100%",
                    padding: "12px",
                    fontSize: "2rem",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    marginTop: "5px",
                  }}
                />
              </div>
              <div>
                <label
                  style={{
                    fontWeight: "bold",
                    fontSize: "2rem",
                    color: "black",
                  }}
                >
                  Comment
                </label>
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  required
                  placeholder="Enter Commment that you enter in video"
                  style={{
                    width: "100%",
                    padding: "12px",
                    fontSize: "2rem",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    minHeight: "100px",
                    marginTop: "5px",
                  }}
                ></textarea>
              </div>
              <button
                type="submit"
                style={{
                  padding: "15px 25px",
                  backgroundImage: `url(${backgroundImage})`,
                  color: "black",
                  fontSize: "1.8rem",
                  fontWeight: "bold",
                  border: "1px solid #ccc",
                  borderRadius: "100px",
                  cursor: "pointer",
                  width: "100%",
                  marginTop: "20px",
                  boxShadow: "0 40px 100px rgba(230, 24, 24, 0.1)",
                }}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        ) : (
          <p>No ads available.</p>
        )}
      </div>
    </Layout>
  );
};

export default LongEarnAds;
